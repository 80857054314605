import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CISIcon } from "../../assets/svg";
import style from "./mainSider.module.less";
import cn from "classnames";
import { paths } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { isAccessAllowed } from "../../helpers";
import keys, { featureKeys } from "../../constants/keys";
import { useKeycloak } from "@react-keycloak/web";
import classNames from "classnames";
import apiServices from "../../config/apiServices";
import i18n, {languageKeys} from "../../i18n"
import { updateTitle } from "ducks/slices/titlePageSlice";
import { useSiderItems } from "./siderItems";

const MainSider = () => {
  const siderItems = useSiderItems();
  const { pathname } = useLocation();
  const visibleSiderModules = useRef(null);

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const tokensInfo = useSelector((state) => state.auth.tokens);

  const [activeKeys, setActiveKeys] = useState([]);
  const [open, setOpen] = useState(false)

  const modules = useMemo(() => {
    return tokensInfo.info.module || userProfile.config.MODULES || [];
  }, [tokensInfo]);

  // config hiển thị các module ra sider
  useEffect(() => {
    visibleSiderModules.current = false;
  }, [modules]);

  // tìm kiếm và set active tab lúc khởi tạo
  useEffect(() => {
    if (activeKeys.length === 0 && pathname !== paths.cis) {
      setActiveTab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeys, pathname]);

  // tìm kiếm và set active tab lúc chuyển trang
  useEffect(() => {
    if (activeKeys.length !== 0) {
      setActiveTab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const setActiveTab = () => {
    for (let i = 0; i < siderItems.length; i++) {
      const item = siderItems[i];
      if (item.path === pathname) {
        setActiveKeys([item.key]);
        dispatch(updateTitle(item.title))
        break;
      }

      if (item.subs.length > 0) {
        for (let j = 0; j < item.subs.length; j++) {
          const subItem = item.subs[j];
          if (subItem.path === pathname) {
            setActiveKeys([item.key, subItem.key]);
            dispatch(updateTitle(item.title))
            break;
          }
        }
      }
    }
  };
  // xử ký tách gộp, thêm xóa khỏi sider
  const checkingSplitRoutes = (items = []) => {
    try {
      let newItems = [...items];

      if (userProfile?.config?.HIEN_THI_LAYOUT_MODULE) {
        let { TACH = [], GOP = [] } = userProfile?.config?.HIEN_THI_LAYOUT_MODULE;

        for (const item of TACH) {
          newItems = newItems.filter((i) => i.scope !== item);
        }
        return newItems.filter(i => modules.includes(i.scope));
      }
    } catch (error) {
      return items;
    }
  };

  return (
    <Layout.Sider className={style["container"]}>
      <div
          className={cn(style["sider-ctn"], open && style["hover"])}
          onClick={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
      >
        <div className={style["sider"]}>
          {/*<div className={style["logo-ctn"]}>*/}
          {/*  <div className={style["avatar-ctn"]}>*/}
          {/*      {userProfile.benh_vien.TEN_CSKCB}*/}
          {/*    </Avatar>*/}
          {/*  </div>*/}
          {/*  <span>{userProfile.benh_vien.TEN_CSKCB}</span>*/}
          {/*</div>*/}

          <div className={style["deepcare-ctn"]}>
            <CISIcon />
            <span>{i18n.t(languageKeys.cisLabel)}</span>
          </div>

          <div className={style["menu"]}>
            {checkingSplitRoutes(siderItems).map((item) => {
              // hiển thị theo config
              if (visibleSiderModules.current) {
                if (item.key === featureKeys.quan_ly_xet_nghiem_lis && !!modules.includes("LIS")) {
                  return <MenuItem key={item.key} item={item} showIcon />;
                }
              }
              // if (item.key === featureKeys.phau_thuat_thu_thuat && !!!userProfile.config.CO_TIEU_PHAU) {
              //   return null;
              // }

              // xử lý trường hợp đặc biệt là LIS
              if (item.key === featureKeys.quan_ly_xet_nghiem_lis) {
                if (isAccessAllowed(userProfile, item.scope, true)) {
                  return <MenuItem key={item.key} item={item} showIcon activeKeys={activeKeys} setActiveKeys={setActiveKeys} />;
                }
              }
              // hiện thị theo quyền
              if (isAccessAllowed(userProfile, item.key, true)) {
                return <MenuItem key={item.key} item={item} showIcon activeKeys={activeKeys} setActiveKeys={setActiveKeys} />;
              }

              return null;
            })}
          </div>

        </div>
      </div>
    </Layout.Sider>
  );
};

export default MainSider;

const SubMenu = memo(({ item = {}, level = 1, activeKeys = [], setActiveKeys = () => {}, parentKeys = [], openSub }) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (!open && activeKeys.length > 0 && activeKeys.includes(item.key)) {
  //     setOpen(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeKeys, item.key]);

  useEffect(() => {
    !openSub && setOpen(false)
  },[openSub])

  return (
    <>
      <div
        className={cn(style["submenu"], activeKeys.includes(item.key) && style["active"], open && style["open"])}
        onClick={() => setOpen((bool) => !bool)}
      >
        <div className={style["icon"]}>{item.icon}</div>
        <div className={style["title"]}>{item.title}</div>
        <div className={style["toggle-btn"]}>{open ? <UpOutlined /> : <DownOutlined />}</div>
      </div>

      {item.subs.length > 0 && (
        <div className={cn(style["group-menu"], style[`group-menu-level-${level}`], open && style["open"])}>
          {item.subs.map((subItem) => {
            if (isAccessAllowed(userProfile, `${item.key}.${subItem.key}`, true)) {
              // if (subItem.subs.length > 0) {
              //   return (
              //     <SubMenu
              //       key={subItem.key}
              //       item={subItem}
              //       level={level + 1}
              //       activeKeys={activeKeys}
              //       setActiveKeys={setActiveKeys}
              //       parentKeys={[...parentKeys, item.key]}
              //     />
              //   );
              // }
              return (
                <MenuItem
                  key={subItem.key}
                  item={subItem}
                  activeKeys={activeKeys}
                  setActiveKeys={setActiveKeys}
                  parentKeys={[...parentKeys, item.key]}
                />
              );
            }
            return null;
          })}
        </div>
      )}
    </>
  );
});

const MenuItem = memo(({ item = {}, showIcon = false, activeKeys = [], setActiveKeys = () => {}, parentKeys = [] }) => {
  const { keycloak } = useKeycloak();

  if (item.key === featureKeys.quan_ly_xet_nghiem_lis) {
    const accessToken = keycloak.token;
    const refreshToken = keycloak.refreshToken;

    return (
      <a
        href={`${apiServices.URL_LIS}?${keys.access_token}=${accessToken}&${keys.refresh_token}=${refreshToken}`}
        target="_blank"
        rel="noopener noreferrer"
        className={cn(style["menu-item"], activeKeys.includes(item.key) && style["active"])}
      >
        {showIcon ? <div className={classNames(style["icon"])}>{item.icon}</div> : <div style={{ width: 2 }}></div>}
        <div className={style["title"]}>{item.title}</div>
      </a>
    );
  }

  if (item.key === "PACS") {
    return (
      <a
        href={"https://live.draid.ai/"}
        target="_blank"
        rel="noopener noreferrer"
        className={cn(style["menu-item"], activeKeys.includes(item.key) && style["active"])}
      >
        {showIcon ? <div className={classNames(style["icon"])}>{item.icon}</div> : <div style={{ width: 2 }}></div>}
        <div className={style["title"]}>{item.title}</div>
      </a>
    );
  }

  return (
    <Link
      to={item.path}
      className={cn(style["menu-item"], activeKeys.includes(item.key) && style["active"])}
      onClick={() => setActiveKeys([...parentKeys, item.key])}
    >
      {showIcon ? <div className={classNames(style["icon"])}>{item.icon}</div> : <div style={{ width: 2 }}></div>}
      <div className={style["title"]}>{item.title}</div>
    </Link>
  );
});
