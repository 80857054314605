import {
  Calendar,
  Calender,
  ChartBar,
  ChartPie,
  Cissor,
  FileInfo,
  FolderMedical,
  IconRis,
  MedicalSquare,
  Medkit,
  MoneyWithdrawal,
  UserGroup,
  Bundle,
} from "../../assets/svg";
import { paths } from "../../constants";
import { featureKeys, routeApp } from "../../constants/keys";
import i18n, { languageKeys } from "../../i18n";

export const useSiderItems = () => {
  const siderItems = [
    {
      scope: routeApp.CIS,
      key: featureKeys.quan_ly_tiep_don,
      title: i18n.t(languageKeys.menu_Quan_ly_tiep_don),
      icon: <Calendar />,
      path: paths.quan_ly_tiep_don,
      subs: [
        {
          key: featureKeys.tiep_don,
          title: i18n.t(languageKeys.label_Tiep_don),
          icon: <MedicalSquare />,
          path: paths.quan_ly_tiep_don,
          subs: [],
        },
        {
          key: featureKeys.cuoc_hen_sap_toi,
          title: i18n.t(languageKeys.label_quan_ly_lich_hen),
          icon: <Calender />,
          path: paths.quan_ly_cuoc_hen_sap_toi,
          subs: [],
        },
      ],
    },
    {
      scope: routeApp.CIS,
      key: featureKeys.quan_ly_vien_phi,
      title: i18n.t(languageKeys.menu_Quan_ly_thanh_toan),
      icon: <MoneyWithdrawal />,
      path: paths.quan_ly_dich_vu_can_thanh_toan,
      subs: [
        {
          key: featureKeys.quan_ly_dich_vu_can_thanh_toan,
          title: i18n.t(languageKeys.menu_Quan_ly_dich_vu_can_thanh_toan),
          icon: <MoneyWithdrawal />,
          path: paths.quan_ly_dich_vu_can_thanh_toan,
          subs: [],
        },
        {
          key: featureKeys.quan_ly_tam_ung,
          title: i18n.t(languageKeys.quan_ly_tam_ung),
          icon: <MoneyWithdrawal />,
          path: paths.quan_ly_tam_ung,
          subs: [],
        },
        {
          key: featureKeys.quan_ly_cong_no,
          title: i18n.t(languageKeys.quan_ly_cong_no),
          icon: <MoneyWithdrawal />,
          path: paths.quan_ly_cong_no,
          subs: [],
        },
        {
          key: featureKeys.quan_ly_so_hoa_don,
          title: i18n.t(languageKeys.menu_Quan_ly_so_hoa_don),
          icon: <FileInfo />,
          path: paths.quan_ly_so_hoa_don,
          subs: [],
        },
      ],
    },
    {
      scope: routeApp.CIS,
      key: featureKeys.quan_ly_kham_benh,
      title: i18n.t(languageKeys.menu_Quan_ly_kham_benh),
      icon: <Medkit />,
      path: paths.quan_ly_kham_benh,
      subs: [
        {
          key: featureKeys.quan_ly_kham_benh,
          title: i18n.t(languageKeys.menu_Quan_ly_kham_benh),
          icon: <MedicalSquare />,
          path: paths.quan_ly_kham_benh,
          subs: [],
        },
        {
          key: featureKeys.quan_ly_chi_dinh_dich_vu,
          title: i18n.t(languageKeys.menu_Quan_ly_kham_benh),
          icon: <MedicalSquare />,
          path: paths.quan_ly_dv_chi_dinh,
          subs: [],
        },
      ],
    },
    {
      scope: routeApp.CIS,
      key: featureKeys.quan_ly_benh_nhan,
      title: i18n.t(languageKeys.menu_Quan_ly_benh_nhan),
      icon: <UserGroup />,
      path: paths.quan_ly_benh_nhan,
      subs: [],
    },
    {
      scope: routeApp.DUOC,
      key: featureKeys.quan_ly_duoc_ngoai_tru,
      title: i18n.t(languageKeys.menu_Quan_ly_duoc_ngoai_tru),
      icon: <FolderMedical />,
      path: paths.ban_thuoc,
      subs: [
        {
          key: featureKeys.ban_thuoc,
          title: i18n.t(languageKeys.menu_Ban_thuoc),
          icon: <ChartPie />,
          path: paths.ban_thuoc,
          subs: [],
        },
        {
          key: featureKeys.ton_kho,
          title: i18n.t(languageKeys.field_ton_kho),
          icon: <ChartPie />,
          path: paths.ton_kho,
          subs: [],
        },
        {
          key: featureKeys.phieu_xuat_nhap,
          title: i18n.t(languageKeys.menu_Phieu_xuat_nhap),
          icon: <MedicalSquare />,
          path: paths.quan_ly_phieu_nhap_xuat,
          subs: [],
        },
        // {
        //   key: featureKeys.kho_duoc_ngoai_tru,
        //   title: i18n.t(languageKeys.menu_Kho_duoc_ngoai_tru),
        //   icon: <MedicalSquare />,
        //   path: paths.kho_duoc_ngoai_tru,
        //   subs: [],
        // },
        {
          key: featureKeys.nha_cung_cap,
          title: i18n.t(languageKeys.menu_Nha_cung_cap),
          icon: <MedicalSquare />,
          path: paths.nha_cung_cap,
          subs: [],
        },
      ],
    },
    {
      scope: routeApp.RIS,
      key: featureKeys.quan_ly_chan_doan_hinh_anh_ris,
      title: i18n.t(languageKeys.ris_nhap_tay) + "/PACS",
      icon: <IconRis />,
      path: paths.tra_ket_qua_cdha,
      subs: [
        {
          key: featureKeys.mau_mo_ta_cdha,
          title: i18n.t(languageKeys.menu_mau_mo_ta_cdha),
          path: paths.mau_mo_ta_cdha,
          subs: [],
        },
        {
          key: featureKeys.tra_ket_qua_cdha,
          title: i18n.t(languageKeys.menu_tra_kq_cdha),
          path: paths.tra_ket_qua_cdha,
          subs: [],
        },
        {
          key: "PACS",
          title: "PACS",
          path: "https://live.draid.ai/",
          subs: [],
        },
      ],
    },
    {
      scope: routeApp.CIS,
      key: featureKeys.phau_thuat_thu_thuat,
      title: i18n.t(languageKeys.menu_phauthuat_thuthuat),
      icon: <Cissor />,
      path: paths.phauthuat_thu_thuat,
      subs: [],
    },
    {
      scope: routeApp.CIS,
      key: featureKeys.quan_ly_goi_dv,
      title: i18n.t(languageKeys.quan_ly_goi_dv),
      icon: <Bundle />,
      path: paths.quan_ly_dk_goi_dv,
      subs: [
        {
          key: featureKeys.quan_ly_dk_goi_dv,
          title: i18n.t(languageKeys.quan_ly_dk_goi_dv),
          path: paths.quan_ly_dk_goi_dv,
          subs: [],
        },
        {
          key: featureKeys.quan_ly_su_dung_goi_dv,
          title: i18n.t(languageKeys.quan_ly_su_dung_goi_dv),
          path: paths.quan_ly_su_dung_goi_dv,
          subs: [],
        },
        {
          key: featureKeys.danh_sach_goi_dv,
          title: i18n.t(languageKeys.danh_sach_goi_dv),
          path: paths.danh_sach_goi_dv,
          subs: [],
        },
      ],
    },
    {
      scope: routeApp.CIS,
      key: featureKeys.bang_dieu_khien,
      title: i18n.t(languageKeys.menu_Bang_dieu_khien),
      icon: <ChartBar />,
      path: paths.bang_dieu_khien,
      subs: [],
    },
    // {
    //   key: featureKeys.ho_tro_khach_hang,
    //   title: i18n.t(languageKeys.menu_Ho_tro_khach_hang),
    //   icon: <Question width="24" height="24" />,
    //   path: paths.ho_tro_khach_hang,
    //   subs: [],
    // },
  ];
  return siderItems;
}; 
