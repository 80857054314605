import React, { useEffect, useMemo, useState } from "react";
import style from "./subHeader.module.less";
import { Avatar, Badge, Button, Divider, Layout, List, Menu, Popover, Space, Spin } from "antd";
import { DownOutlined, GlobalOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useChangeLanguage } from "../../hooks";
import { useHistory } from "react-router-dom";
import { HLog, common_post, isAccessAllowed, locdau } from "helpers";
import { LogoText } from "assets/img";
import { DuocNgoaiTruIcon, RISIcon, VnFlag, EnFlag, FileCheckAlt, Logout2 } from "assets/svg";
import keys, { routeApp } from "../../constants/keys";
import i18n, { languageKeys, languages } from "../../i18n";
import CommonNotification from "components_v2/CommonNotification";
import { setCheckedListNoti, setUnCheckListNoti } from "ducks/slices/risReloadSilce";
import moment from "moment";
import apis from "../../constants/apis";
import Constants from "constants/Constants";
import { PathRis } from "app/RisRoute";
import { onVisibleAccManagment } from "ducks/slices/titlePageSlice";
import { Chatbot } from "features";
import { Images } from "components";

const SubHeader = ({ menuItem = [], onNavigate = () => { }, module = "" }) => {

  const history = useHistory();
  const { logout } = useAuth();
  const { user: userProfile, tokens } = useSelector((state) => state.auth);
  const { unCheckListNoti, checkedListNoti, reloadKqDv } = useSelector(((state) => state.risReloadSilce));
  const dispatch = useDispatch();
  const { onChangeLanguage } = useChangeLanguage();

  const handleLogout = () => {
    logout();
  };

  const config = useMemo(() => {
    return userProfile?.config;
  }, [userProfile]);

  const handleChangeLanguage = (val) => {
    onChangeLanguage(val);
  };

  // Lấy danh sách noti trong local
  useEffect(() => {
    getListNoti();
  }, []);

  const getListNoti = async (page = 1) => {
    try {
      let body = {
        TU_NGAY: moment().subtract(keys.date_stored_noti, 'd').format('YYYYMMDD'),
        DEN_NGAY: moment().format('YYYYMMDD'),
        limit: 30,
      };

      let res = await common_post(apis.get_notification_ris, body);

      if (res && res.status === 'OK') {
        let { result = [] } = res;
        if (result && Array.isArray(result)) {

          let unChecked = result.filter(i => i && !i.IS_SEEN);
          let checked = result.filter(i => i && i.IS_SEEN);

          dispatch(setUnCheckListNoti(unChecked));
          dispatch(setCheckedListNoti(checked));
        }
      }
    } catch (error) {

    } finally {

    }
  }

  const UserSetting = () => (
    <Space size={3} direction={"vertical"} className={style["inner"]}>
      <Space size={10} style={{ fontWeight: 700, marginBottom: 3 }}>
          <Avatar size={30} src={userProfile?.ANH_DAI_DIEN ? <Images imgId={userProfile.ANH_DAI_DIEN}/> : undefined} icon={<UserOutlined />}/>
        <span>{userProfile.HO + " " + userProfile.TEN}</span>
        <DownOutlined />
      </Space>

      <Button icon={<Logout2 />} type="link" style={{ gap: 8 }} onClick={handleLogout}>
        {i18n.t(languageKeys.common_Dang_xuat)}
      </Button>

      <Popover
        content={() => {
          return (
            <Space size={3} direction={"vertical"} className={style["inner"]}>
              <Button icon={<VnFlag height={20} width={30} />} type="link" onClick={() => handleChangeLanguage(languages.tieng_viet)}>
                &nbsp;{i18n.t(languageKeys.ngon_ngu_tieng_viet)}
              </Button>
              <Button icon={<EnFlag height={20} width={30} />} type="link" onClick={() => handleChangeLanguage(languages.tieng_anh)}>
                &nbsp;{i18n.t(languageKeys.ngon_ngu_tieng_anh)}
              </Button>
            </Space>
          );
        }}
        placement="left"
      >
        <Button icon={<GlobalOutlined />} type="link">
          {i18n.t(languageKeys.common_Doi_ngon_ngu)}
        </Button>
      </Popover>
      {module === 'HOME' && (
        <Button icon={<UserOutlined />} type="link" onClick={() => dispatch(onVisibleAccManagment(true))}>
          {i18n.t(languageKeys.quan_ly_tai_khoan)}
        </Button>
      )}
      {/*<Button icon={<SettingOutlined />} type="link">*/}
      {/*  Quản lý license*/}
      {/*</Button>*/}
      <Divider dashed className={style["divider"]} />
      <p>Version: {keys.VERSION}</p>
    </Space>
  );

  const selectedLogo = useMemo(() => {
    if (config && config.CUSTOM_DOMAIN && config.CUSTOM_DOMAIN.HEADER_LOGO_ID) {
      // return api lấy logo
      return window.GATEWAY + `cis-public/public/file?ID=${config.CUSTOM_DOMAIN.HEADER_LOGO_ID}`;
    } else {
      return LogoText;
    }
  }, [config]);

  const renderLogo = (moduleDC) => {
    let logo = <></>;
    let title = "";
    switch (moduleDC) {
      case routeApp.DUOC:
        logo = <DuocNgoaiTruIcon />;
        title = i18n.t(languageKeys.tool_tip_duoc);
        break;
      case routeApp.RIS:
        logo = <RISIcon />;
        title = i18n.t(languageKeys.menu_Quan_ly_chan_doan_hinh_anh_ris);
        break;
      default:
        logo = <img src={selectedLogo} alt="logo" className={style["dcLogo"]}/>;
        title = "";
        break;
    }
    return { title, logo };
  };

  return (
    <Layout.Header className={style["header"]}>
      <div className={style["logo"]}>
        {renderLogo(module).logo}
        <span>{renderLogo(module).title}</span>
      </div>

      <Menu selectedKeys={[history.location.pathname]} className={style["menu"]} mode="horizontal" onClick={onNavigate}>
        {menuItem.map((item) =>
          isAccessAllowed(userProfile, item.featureKey, true) ? (
            <Menu.Item key={item.key} item={item} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ) : (
            <Menu.Item disabled key={item.key} item={item} icon={item.icon} style={{ pointerEvents: "none", opacity: 0.3 }}>
              {item.label}
            </Menu.Item>
          )
        )}
      </Menu>

      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ transform: 'scale(0.5)', height: 42 }}>
          {!!(module && module !== 'HOME') && <Chatbot from={module} key={module} floatBtn={false} />}
        </div>
        {(module === routeApp.RIS && window.location.pathname.includes('tra-ket-qua-cdha')) && <CommonNotification count={+unCheckListNoti?.length} content={<ContentNoti unCheckList={unCheckListNoti} checkedList={checkedListNoti} onReloadNoti={getListNoti} />} />}
        <Popover
          content={UserSetting}
          trigger="click"
          autoAdjustOverflow
          overlayClassName={style["setting"]}
          placement='bottomLeft'
          overlayInnerStyle={{ width: "100%", height: "100%", boxShadow: "none", padding: 0 }}
        >
          <Space size={10} className={style["user"]}>
            <Avatar size={30} src={userProfile?.ANH_DAI_DIEN ? <Images imgId={userProfile.ANH_DAI_DIEN}/> : undefined} icon={<UserOutlined />}/>
            {/* <span>{userProfile.HO + " " + userProfile.TEN}</span> */}
            {/* <DownOutlined /> */}
          </Space>
        </Popover>
      </div>
    </Layout.Header>
  );
};

const ContentNoti = ({
  unCheckList = [],
  checkedList = [],
  onReloadNoti = () => { }
}) => {
  const [notiLoading, setNotiLoading] = useState(false);
  const history = useHistory();
  let dataSource = useMemo(() => {
    try {
      return [...unCheckList, ...checkedList]
    } catch (error) {
      HLog(error);
      return [];
    }
  }, [unCheckList, checkedList]);

  const handleGoToTestResult = async item => {
    try {
      if (item.IMAGE_URL && [Constants.NHAN_KQ_CDHA_VIN, Constants.AI_DA_PHAN_TICH].includes(item.TYPE)) {
        window.open(item.IMAGE_URL, "_blank");
      } else {
        handleAction(item);
      }
      if (!item.IS_SEEN) {
        await handleUpdateNoti(item);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdateNoti = async (item) => {
    let { ID } = item;
    try {
      setNotiLoading(true);
      let body = { "XOA": 0, ID }

      let res = await common_post(apis.update_notification_ris, body);

      if (res && res.status === 'OK') {
        onReloadNoti();
      }
    } catch (error) {

    } finally {
      setNotiLoading(false);
    }

  }

  const handleAction = (item) => {
    try {
      if (item.TYPE == Constants.NHAN_KQ_CDHA_CHI_DINH_VIN) {

        const params = new URLSearchParams({ "RIS": keys.openForm, "PHIEU_ID": item?.PHIEU_ID });
        history.replace({
          pathname: PathRis.traKetQua,
          search: params.toString()
        })
      }
    } catch (error) {

    }
  }

  const getText = (item) => {
    let result = {
      title: "",
      description: ""
    };

    let { TYPE } = item;
    switch (TYPE) {
      case Constants.NHAN_KQ_CDHA_VIN:
        result.title = i18n.language === languages.tieng_viet ? ("Đã có hình ảnh " + item?.TEN_DICHVU) : (item.TEN_DICHVU + " has pictures");
        result.description =
          i18n.language === languages.tieng_viet ? "Hình ảnh " + item?.TEN_DICHVU + " của khách hàng " + item?.TEN + " đã được cập nhật" : "Customer " + locdau(item?.TEN) + "'s " + item.TEN_DICHVU + " has updated !";
        break;
      case Constants.NHAN_KQ_CDHA_CHI_DINH_VIN:
        result.title = i18n.language === languages.tieng_viet ? "Đã có kết quả " + item?.TEN_DICHVU : item?.TEN_DICHVU + " results are available";
        result.description = i18n.language === languages.tieng_viet 
        ? "Kết quả " + item?.TEN_DICHVU + " của khách hàng " + item?.TEN + " đã được cập nhật"
        : item?.TEN_DICHVU + " result of " + locdau(item?.TEN) + "'s has updated !";
        break;
      case Constants.AI_DA_PHAN_TICH:
        result.title = i18n.language === languages.tieng_viet ? "AI đã xử lý hình ảnh" : "AI processed the images";
        result.description = i18n.language === languages.tieng_viet ? "Kết quả " + item?.TEN_DICHVU + " của khách hàng " + item?.TEN + " đã được xử lý"
        : item?.TEN_DICHVU + " result of " + locdau(item?.TEN) + "'s has processed !";
        break;
      default:
        break;
    }

    return result;
  }

  return (
    <Spin spinning={notiLoading}>
      <List
        className={`${style["notification-list"]} ${style['style-scroll-bar']}`}
        itemLayout="horizontal"
        dataSource={dataSource}
        style={{ width: 400 }}
        renderItem={(item) => (
          <List.Item className={style['list-wrapper-item']}>
            {/* <Skeleton avatar title={false} loading={item.loading} active> */}
            <div>
              <div className={style['wrapper-icon-docx']}><FileCheckAlt className={style['icon-docx']} /></div>
            </div>
            <div style={{ padding: '0 12px', cursor: 'pointer' }} onClick={() => handleGoToTestResult(item)}>
              <div><b className="bold-txt">{getText(item).title}</b></div>
              <div>
                <sub>{getText(item).description}</sub>
              </div>
            </div>
            <div>
              <div>{item?.NGAY ? moment(item?.NGAY).format('DD/MM') : '../..'}</div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Badge color={!!item?.IS_SEEN ? "green" : "red"} className={style['badge-dot']} />
              </div>
            </div>
            {/* </Skeleton> */}
          </List.Item>
        )}
      />
    </Spin>
  );
};

export default SubHeader;
